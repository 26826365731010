import { stringify } from 'qs'
import omitBy from 'lodash/omitBy'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import { DICTIONARIES } from '@/constants'
import type { ApiResponse, LibraryItem, PaginatedResponse } from '~/types'
import { useLibrariesStore } from '@/store/libraries'
import type { ExclusiveUnit, DownloadOffer } from '~/types/properties'

const getKeyByValue = (value: string): string | undefined => {
  const entries = Object.entries(DICTIONARIES)
  for (const [key, val] of entries) {
    if (val === value) return key
  }
  return undefined
}

export const useGetProperties = async (params: Object) => {
  const query = omitBy(toRaw(params), (f) => f === null || f === undefined)

  const queryFormatted = stringify(query, { arrayFormat: 'indices', encode: false })

  return await useAPIPropertiesFetch(`residentials?${queryFormatted}`)
}

export const useGetPropertiesTags = async () => {
  return await useAPIPropertiesFetch<ApiResponse<LibraryItem[]>>(`tags`)
}

export const useGetMapProperties = async (params: Object) => {
  const query = omitBy(toRaw(params), (f) => f === null || f === undefined)
  const queryFormatted = stringify(query, { arrayFormat: 'indices', encode: false })

  return await useAPIPropertiesFetch(`residentials/map?${queryFormatted}`)
}

export const useGetProperty = async (id: string) => {
  const { data, error } = await useAPIPropertiesFetch(`residentials/${id}`)
  if (error) throw createError(error)
  return data as any
}

// flexible composable to fetch any library
export const useGetPropertiesLibrary = async (libraryName: DICTIONARIES): Promise<LibraryItem[]> => {
  const libraryKey = getKeyByValue(libraryName)

  const librariesStore = useLibrariesStore()
  // Convert libraryName to Start Case and then to Camel Case
  const camelCaseName = camelCase(libraryKey)
  const getterName = `get${upperFirst(camelCaseName)}`
  const setterName = `set${upperFirst(camelCaseName)}`
  if (librariesStore[getterName]?.length) {
    return librariesStore[getterName]
  } else {
    try {
      const { data } = await useAPIPropertiesFetch(`dictionary/${libraryName}`)

      const library = (data as LibraryItem[]) || []
      const storeSetter = librariesStore[setterName as keyof typeof librariesStore]
      if (!storeSetter) {
        throw createError(`Library ${libraryName} not found in store`)
      } else {
        storeSetter(library)
        return library
      }
    } catch (error: any) {
      throw createError(error)
    }
  }
}

export const useCreatePropertyOffer = async (body: any) => {
  const { data, error } = await useAPIFetch('/property-catalogue/offers', {
    method: 'post',
    body,
  })
  if (error.value) throw createError(error.value)

  return (data.value as any).data
}

export const useGetListPropertyOffer = async (id: number) => {
  const { data, error } = await useAPIFetch(`/leads/${id}/offers`)
  if (error.value) throw createError(error.value)
  return (data.value as any).data
}

export const useGetPublicPropertyOffer = async (hash: string) => {
  const config = useRuntimeConfig()
  const { data } = await $fetch(`${config.public.API_URL}property-catalogue/public/offers/${hash}`)
  return data
}

export const useGetExclusiveUnits = async (query: Record<string, any>) => {
  const config = useRuntimeConfig()

  const queryFormatted = stringify(query, { arrayFormat: 'indices', encode: false })

  const { data, error } = await useAPIFetch<PaginatedResponse<ExclusiveUnit[]>>(
    `external/properties/exclusive-units?${queryFormatted}`,
    { baseURL: config.public.API_URL_PROPERTY_CATALOGUE }
  )

  if (error.value) throw createError(error.value)

  return data.value
}

export const exportSalesOffer = async (propertyId: number, unitId: number, body: DownloadOffer) => {
  const config = useRuntimeConfig()
  return await new Promise((resolve, reject) => {
    useAPIFetch(`properties/${propertyId}/sales-offers/units/${unitId}`, {
      baseURL: config.public.API_URL_PROPERTY_CATALOGUE,
      method: 'post',
      body,
      onResponse({ response }) {
        if (!response.ok) reject(createError(response._data))
        const filename = response.headers.get('Content-Disposition')?.split('filename=')[1]?.replaceAll('"', '')

        resolve({
          filename,
          data: response._data,
        })
      },
      onResponseError({ response }) {
        createError(response._data)
      },
    })
  })
}
