export async function useAPIPropertiesFetch<T>(endpoint: string) {
  const config = useRuntimeConfig()

  try {
    const response = await fetch(`${config.public.API_URL_PROPERTY_CATALOGUE}${endpoint}`)
    if (!response.ok) {
      throw response
    }
    const data = await response.json()
    return data as T
  } catch (error: any) {
    console.error('Fetch error:', error.status)
    throw error
  }
}
